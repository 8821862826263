import React from "react";
import { NavbarLoggedIn } from "../components/NavbarSignedIn.jsx";
import { Footer } from "../components/Footer.jsx";
import Dsgvo from "./PaminaDSGVO/Dsgvo.tsx";
// import "./listing.css";
import { IconUser2 } from "../icons/IconUser2.jsx";

export default function DsgvoPage({ supabase }) {
    return (
        <div className="web-listing">
            <NavbarLoggedIn supabase={supabase} icon={<IconUser2 className="icon-user-2" />} />
            <Dsgvo />
            <div className="web-listing-v">
                <Footer />
            </div>
        </div>
    );
};
