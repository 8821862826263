// @src/context/Auth.jsx
import { createContext, useContext, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect } from 'react';

// https://medium.com/@seojeek/protected-routes-in-react-router-6-with-supabase-authentication-and-oauth-599047e08163
// https://browsee.io/blog/authentication-with-react-router-v6-step-by-step-guide/
const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children, supabase }) => {
    const [user, setUser] = useState(null);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const { data: listener } = supabase.auth.onAuthStateChange(
            (_event, session) => {
                console.log('session onAuthStateChange: ', session);
                setSession(session);
                setUser(session?.user || null);
                setLoading(false);
            }
        );
        return () => {
            listener?.subscription.unsubscribe();
        };
    }, []);

    // In case we want to manually trigger a signIn (instead of using Auth UI)
    const signIn = async () => {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: { skipBrowserRedirect: false },
        });
        console.log('data: ', data);
        console.log('error: ', error);
        return { data, error };
    };

    const signOut = async () => {
        const { error } = await supabase.auth.signOut();
        console.log('error: ', error);
        if (!error) {
            setUser(null);
            setSession(null);
        }
        return { error };
    };

    return (
        <AuthContext.Provider value={{ user, signIn, signOut }}>
            {!loading ? children : `Loading...`}
        </AuthContext.Provider>
    );

};
export const RequireAuth = ({ supabase }) => {
    const { user } = useAuth();

    // useEffect(() => {
    //     if (user === null) {

    //         supabase.auth.getSession().then(({ data: { session } }) => {
    //             setUser(session)
    //         })

    //         const {
    //             data: { subscription },
    //         } = supabase.auth.onAuthStateChange((_event, session) => {
    //             setUser(session)
    //         })

    //         return () => subscription.unsubscribe()
    //     }
    // }, [user])

    const location = useLocation();
    if (!user) {
        return (
            <Navigate
                to={{ pathname: "/login", state: { from: location } }}
                replace
            />
        );
    }
    return <Outlet />;
};