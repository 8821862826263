import React from "react";
import { NavbarLoggedIn } from "../components/NavbarSignedIn.jsx";
import { Footer } from "../components/Footer";
import { useAuth } from "../Auth/Auth";

// https://docs.stripe.com/testing#cards
export const PricingTable = ({ supabase }) => {
    const { user } = useAuth();
    return (<>
        <NavbarLoggedIn supabase={supabase} />
        <stripe-pricing-table pricing-table-id="prctbl_1QPXBEEY1XGU2Go43zuw9sMt"
            publishable-key="pk_test_51QPGyhEY1XGU2Go4cnHCWQJP6WmNzWgT2FX252EfKJtENedTw4GoydeSyVqPlH8mN6zzcaul4GRtVWKVPKYJkRdh006bQ1mgQT"
            client-reference-id={user.id}
            customer-email={user.email}>
        </stripe-pricing-table>
        <Footer />
    </>
    );
};