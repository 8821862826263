import PropTypes from "prop-types";
import React from "react";
import { IconShareIos } from "../icons/IconShareIos";
import { StatusDropdown } from "./StatusDropdown";


export const Listing = ({ userRanking, supabase }) => {
    const StatusChanged = async (event) => {
        await supabase
            .from("UserRankings")
            .update({ status: event.target.value })
            .eq('id', userRanking.id)
    }
    // var statusText = ""
    // switch (userRanking.status) {
    //     case 0:
    //         statusText = 'New'
    //         break;
    //     case 1:
    //         statusText = 'Interested'
    //         break;
    //     case 2:
    //         statusText = 'CV Send'
    //         break;
    //     case 3:
    //         statusText = 'Denied'
    //         break;
    //     case 4:
    //         statusText = 'Interview'
    //         break;
    //     case 5:
    //         statusText = 'Accepted'
    //         break;
    // }
    return (
        <div id={userRanking.id} className="listing">
            <div className="side">
                <div className="element">{userRanking.ranking}.</div>

                <StatusDropdown statusText={userRanking.status} onChange={StatusChanged} />
            </div>

            <div className="content">
                <p className="p">
                    {userRanking.job.title}
                </p>

                <div className="text-wrapper-2">Company: {userRanking.job.company}</div>

                <div className="requirements-c-uni">
                    Requirements: <br />
                    C# <br />
                    Uni Abschluss
                    <br />
                    Teamfähigkeit
                </div>
            </div>

            <a className="link-2" href={"https://de.indeed.com/viewjob?from=serp&vjs=3&jk=" + userRanking.job.jobID} target="_blank" rel="noreferrer">
                <IconShareIos className="icon-share-ios" />
                <div className="text-wrapper-3">Goto Posting</div>
            </a>
        </div >
    );
};

Listing.propTypes = {
    userRanking: PropTypes.object,
    supabase: PropTypes.object,
};
