/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconUser2 = ({ className }) => {
    return (
        <svg
            className={`icon-user-2 ${className}`}
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path-2"
                d="M9.16699 32.5C9.16699 32.5 15.0003 30 16.667 28.3333C18.3337 26.6667 13.3337 26.6667 13.3337 18.3333C13.3337 10 20.0003 10 20.0003 10C20.0003 10 26.667 10 26.667 18.3333C26.667 26.6667 21.667 26.6667 23.3337 28.3333C25.0003 30 30.8337 32.5 30.8337 32.5"
                stroke="#CF5AB7"
                strokeLinecap="round"
                strokeWidth="3"
            />

            <path
                className="path-2"
                clipRule="evenodd"
                d="M19.9997 36.6663C29.2044 36.6663 36.6663 29.2044 36.6663 19.9997C36.6663 10.7949 29.2044 3.33301 19.9997 3.33301C10.7949 3.33301 3.33301 10.7949 3.33301 19.9997C3.33301 29.2044 10.7949 36.6663 19.9997 36.6663Z"
                fillRule="evenodd"
                stroke="#CF5AB7"
                strokeWidth="3"
            />
        </svg>
    );
};
