import './tailwindOutput.css';
import './styleguide.css';
import './App.css';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Login } from './Auth/Login.jsx';
import ImpressumPage from './DSGVO/ImpressumPage.tsx';
import DsgvoPage from './DSGVO/DsgvoPage.tsx';
import { WebLandingpage } from './Landingpage/Landingpage.jsx';
import { JobListing } from './RestrictedContent/JobListing.jsx';
import { Onboarding } from './RestrictedContent/Onboarding.jsx';
import { ResetPassword } from './RestrictedContent/Account/ResetPassword.jsx';
import { ChangePassword } from './RestrictedContent/Account/ChangePassword.jsx';
import { PaymentSuccessful } from './RestrictedContent/Account/PaymentSuccessful.jsx';
import { createClient } from '@supabase/supabase-js'

import { PricingTable } from './RestrictedContent/PricingTable.jsx';
import { AuthProvider, RequireAuth } from './Auth/Auth.jsx';

const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON)

function App() {

  return (
    <AuthProvider supabase={supabase}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" >
              <Route index element={<WebLandingpage />} />
              <Route path="onboarding" element={<Onboarding />} />
              <Route path="impressum" element={<ImpressumPage />} />
              <Route path="dsgvo" element={<DsgvoPage />} />
              <Route path="login" element={<Login supabase={supabase} />} />
              <Route element={<RequireAuth supabase={supabase} />}>
                <Route path="joblisting" element={<JobListing supabase={supabase} />} />
                <Route path="pricingtable" element={<PricingTable />} />
                <Route path="resetpw" element={<ResetPassword supabase={supabase} />} />
                <Route path="changepw" element={<ChangePassword supabase={supabase} />} />
                <Route path="paymentsuccessful" element={<PaymentSuccessful supabase={supabase} />} />
              </Route>
              {/* <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
