import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Buttons = ({
    primary = true,
    divClassName = "",
    className = "",
    text = "Button",
    onClick,
}) => {
    return (<>
        {primary === true && (
            <div onClick={onClick} className={`buttons buttons-instance ${className}`}>
                <div className={`style-primary ${className}`}>
                    <div className={`text-wrapper ${divClassName}`}>{text}</div>
                </div>
            </div>
        )}
        {primary === false && (
            <div onClick={onClick} className={`buttons style-secondary ${className}`}>
                <div className={`div ${divClassName}`}>{text}</div>
            </div>
        )}
    </>
    );
};

Buttons.propTypes = {
    primary: PropTypes.oneOf([true, false]),
    text: PropTypes.string,
    onClick: PropTypes.func,
};
