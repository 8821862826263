/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconShareIos = ({ className }) => {
    return (
        <svg
            className={`icon-share-ios ${className}`}
            fill="none"
            height="71"
            viewBox="0 0 70 71"
            width="70"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path-2"
                d="M34.9997 44.25V9.25"
                stroke="black"
                strokeLinecap="round"
                strokeWidth="3"
            />

            <path
                className="path-2"
                d="M26.25 15.083L35 6.33301L43.75 15.083"
                stroke="black"
                strokeLinecap="round"
                strokeWidth="3"
            />

            <rect
                className="rect"
                height="35"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                width="35"
                x="17.5"
                y="26.75"
            />
        </svg>
    );
};
