import React from "react";

// TODO: When Time: https://dev.to/alexeagleson/how-to-create-and-publish-a-react-component-library-2oe#using-your-library
export default function Impressum({mail, phone = "017696352308"}) {
  return (
      <div className="DSGVO-Container Container">
        <h1 className="mt-4">Impressum</h1>
        <p>
          Ludwig Horner
          <br />
          Lindenstr. 1
          <br />
          94339 Leiblfing
        </p>

        <h2>Kontakt</h2>
        <p>
          Telefon: {phone}
          <br />
          E-Mail: {mail}
        </p>

        <h2>Umsatzsteuer-ID</h2>
        <p>
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
          Umsatzsteuergesetz:
          <br />
          DE313109681
        </p>

        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europ&auml;ische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>

        <h2>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p className="mb-5">Quelle: https://www.e-recht24.de</p>
      </div>
  );
}
