import React from "react";
import { useEffect, useState } from 'react';
import { Listing } from "../components/Listing";
import { NavbarLoggedIn } from "../components/NavbarSignedIn.jsx";
import { Footer } from "../components/Footer";
import { IconUser2 } from "../icons/IconUser2";

import "./listing.css";

export const JobListing = ({ supabase }) => {

    const [rankingReady, setRankingReady] = useState(false);
    const [rankingData, setRankingData] = useState({});
    const [goals, setGoals] = useState({});

    // Todo: Refresh Page every 30s?
    useEffect(() => {
        const fetchData = async () => {
            const { data: goalsData } = await supabase
                .from("Goals")
                .select();
            const { data: rankingData } = await supabase
                .from("UserRankings")
                .select("*, job ( id, title, company, location, jobID )");
            if (goalsData === null || goalsData.length === 0) {
                console.log("Data Insert")
                await supabase.from("Goals").insert({
                    workBalance: localStorage.getItem('workBalance'),
                    careerChange: localStorage.getItem('careerChange'),
                    careerGoals: localStorage.getItem('careerGoals'),
                    cVOverview: localStorage.getItem('cVOverview'),
                });
            }
            if (goalsData[0].paymentSuccess === false) {
                window.location.assign("/pricingtable")
                return
            }
            setGoals(goalsData);
            setRankingData(rankingData);
            if (goalsData.length > 0)
                setRankingReady(goalsData[0].rankingReady);
        }

        fetchData().catch(console.error);
    }, [supabase])

    return (
        <div className="web-listing">
            {/* asdf {JSON.stringify(rankingData)} */}
            {/* asdfasdf {rankingReady} */}
            <NavbarLoggedIn supabase={supabase} icon={<IconUser2 className="icon-user-2" />} />
            <div className="web-listing-v">
                <div className="web-home">
                    {!rankingReady &&
                        <div className="loading">
                            <div className="container-2">
                                <div className="div-2">
                                    <div className="div-2">
                                        <div className="div-3">
                                            <div className="heading-wrapper">
                                                <p className="heading">
                                                    <span className="span">AI Search</span>

                                                    <span className="text-wrapper-5">
                                                        {" "}
                                                        is in progress, please wait ...
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="buttons" />
                                </div>

                                <img
                                    className="placeholder-image"
                                    alt="Placeholder"
                                    src="https://c.animaapp.com/cRZENORy/img/placeholder-image.png"
                                />
                            </div>
                        </div>
                    }
                    {rankingReady &&
                        <div className="list" key="1">
                            <div className="container-3">
                                <div className="section-title">
                                    <div className="heading-wrapper">
                                        <p className="heading">
                                            <span className="span">AI Search</span>

                                            <span className="text-wrapper-5"> Results</span>
                                        </p>
                                    </div>
                                </div>

                                {rankingData.map(e => {
                                    return (
                                        <div key={e.id} >
                                            <Listing userRanking={e} supabase={supabase} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
                <Footer />
            </div>
        </div>
    );
};
