import React from "react";
import { useState } from "react";
import { Buttons } from "../components/Buttons";
import { NavbarOnboarding } from "../components/NavbarOnboarding";
import { OnboardingStatusBar } from "../components/OnboardingStatusBar";
import { FooterImpressum } from "../components/FooterImpressum";
import { SelectionButton } from "../components/SelectionButton";
import "./styleOnboarding.css";

// Add Return Button func -- https://stackoverflow.com/questions/45031085/react-native-device-back-button-handling
export const Onboarding = () => {
    const [page, setPage] = useState(1);
    const [workBalance, setWorkBalance] = useState(true);
    const [careerChange, setCareerChange] = useState(true);
    const [careerGoals, setCareerGoals] = useState("");
    const [cVOverview, setCVOverview] = useState("");
    const max = 6;

    const onNext = () => {
        if (page < max) {
            setPage(page + 1)
        } else {
            localStorage.setItem('workBalance', workBalance);
            localStorage.setItem('careerChange', careerChange);
            localStorage.setItem('careerGoals', careerGoals);
            localStorage.setItem('cVOverview', cVOverview);
            window.location.assign('/login')
        }
    }

    return (
        <>
            <NavbarOnboarding />
            <div className="web-onboarding">
                <div className="web-onboarding-v">

                    <div className="content-onboarding">
                        <OnboardingStatusBar State={page} Max={max} />
                        <div className="onboard-title">
                            {
                                {
                                    1: <>Glad you are here!</>,
                                    2: <>What is most important for you?</>,
                                    3: <>Are you interested in a career change?</>,
                                    4: <>Anything else?</>,
                                    5: <>Almost Done!</>,
                                    6: <>CV Overview</>,
                                }[page]
                            }
                        </div>

                        {
                            {
                                1: <p className="p">
                                    We’d like to get to know you and your career Goals. So we can find
                                    the right Job offers for you
                                </p>,
                                2: <div className="selection-box">
                                    <div onClick={() => setWorkBalance(true)} className="hover:cursor-pointer" >
                                        <SelectionButton active={workBalance} text="Work Life Balance" /></div>
                                    <div onClick={() => setWorkBalance(false)} className="hover:cursor-pointer" >
                                        <SelectionButton active={!workBalance} text="Make Career" /></div>
                                </div>,
                                3: <div className="selection-box">
                                    <div onClick={() => setCareerChange(true)} className="hover:cursor-pointer" >
                                        <SelectionButton active={careerChange} text="Yes" />
                                    </div>
                                    <div onClick={() => setCareerChange(false)} className="hover:cursor-pointer" >
                                        <SelectionButton active={!careerChange} text="No" />
                                    </div>
                                </div>,
                                4: <><p className="p">
                                    We’d like to get to know you and your career Goals. So we can find
                                    the right Job offers for you
                                </p>
                                    <textarea value={careerGoals} onChange={(event) => {
                                        setCareerGoals(event.target.value);
                                    }} className="onboard-textarea" /></>,
                                5: <p className="p">
                                    To help us understand your previous experience and skills, please upload your CV. First, you'll need to create an account and log in. Once you're done, we'll show you available job opportunities that match your profile.
                                </p>,
                                6: <>CV Overview<br></br>
                                    <textarea value={cVOverview} onChange={(event) => {
                                        setCVOverview(event.target.value);
                                    }} className="onboard-textarea" /></>,
                            }[page]
                        }

                        <div onClick={onNext} className="hover:cursor-pointer mt-10">
                            <Buttons
                                text="Continue"
                            />
                        </div>
                    </div>
                </div>
            </div >
            <FooterImpressum
                className="onboard-footer"
            />
        </>
    );
};
