/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconUser = ({ className }) => {
    return (
        <svg
            className={`icon-user ${className}`}
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="path-2"
                d="M9.16669 32.5C9.16669 32.5 15 30 16.6667 28.3333C18.3334 26.6667 13.3334 26.6667 13.3334 18.3333C13.3334 10 20 10 20 10C20 10 26.6667 10 26.6667 18.3333C26.6667 26.6667 21.6667 26.6667 23.3334 28.3333C25 30 30.8334 32.5 30.8334 32.5"
                stroke="#CF5AB7"
                strokeLinecap="round"
                strokeWidth="3"
            />

            <path
                className="path-2"
                clipRule="evenodd"
                d="M20 36.6667C29.2047 36.6667 36.6666 29.2048 36.6666 20C36.6666 10.7953 29.2047 3.33334 20 3.33334C10.7952 3.33334 3.33331 10.7953 3.33331 20C3.33331 29.2048 10.7952 36.6667 20 36.6667Z"
                fillRule="evenodd"
                stroke="#CF5AB7"
                strokeWidth="3"
            />
        </svg>
    );
};
