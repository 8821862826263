import React from "react";
import { FooterImpressum } from "../components/FooterImpressum";
import { TypeDefault } from "../components/TypeDefault";
import { IconFacebook1 } from "../icons/IconFacebook1";
import { IconInstagram1 } from "../icons/IconInstagram1";
import { IconLinkedin1 } from "../icons/IconLinkedin1";
import { IconX1 } from "../icons/IconX1";
import { IconYoutube1 } from "../icons/IconYoutube1";
import { Buttons } from "../components/Buttons";
import "./style.css";

export const Footer = ({ }) => {
    return (
        <>
            <div className="footer">
                <div className="footer-content">
                    <div className="footer-newsletter">
                        <img
                            className="logo-p-only"
                            alt="Logo p only"
                            src="https://c.animaapp.com/K7QBe6oZ/img/logo-p-only-1-1@2x.png"
                        />

                        <p className="footer-text">
                            Subscribe to our newsletter for the latest updates on features
                            and job opportunities.
                        </p>

                        <div className="div-6">
                            <div className="input-form">
                                <TypeDefault
                                    className="type-default-alternate-false"
                                    divClassName="design-component-instance-node-2"
                                    text="Your email here"
                                />
                                <Buttons
                                    primary={false}
                                    className="style-secondary-small-false-alternate-false-icon-position-no-icon"
                                    divClassName="design-component-instance-node-2"
                                    text="Join"
                                />
                            </div>

                            <p className="by-subscribing-you">
                                By subscribing, you consent to our Privacy Policy and agree
                                to receive updates.
                            </p>
                        </div>
                    </div>

                    <div className="footer-links">
                        <div className="footer-links-column">
                            <div className="footer-links-title">Quick Links</div>

                            <div className="">
                                <div className="footer-div-wrapper">
                                    <div className="text-wrapper-11">Job Search</div>
                                </div>

                                <div className="footer-div-wrapper">
                                    <div className="text-wrapper-11">Cover Letters</div>
                                </div>

                                <div className="footer-div-wrapper">
                                    <div className="text-wrapper-11">AI Tools</div>
                                </div>

                                <div className="footer-div-wrapper">
                                    <div className="text-wrapper-11">Success Stories</div>
                                </div>

                                <div className="footer-div-wrapper">
                                    <div className="text-wrapper-11">Contact Us</div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-links-column">
                            <div className="footer-links-title">Stay Connected</div>

                            <div className="">
                                <div className="footer-link-instance">
                                    <IconFacebook1 className="icon-instance-node-2" />
                                    <div className="text-wrapper-12">Facebook</div>
                                </div>

                                <div className="footer-link-instance">
                                    <IconInstagram1 className="icon-instance-node-2" />
                                    <div className="text-wrapper-12">Instagram</div>
                                </div>

                                <div className="footer-link-instance">
                                    <IconX1 className="icon-instance-node-2" />
                                    <div className="text-wrapper-12">X</div>
                                </div>

                                <div className="footer-link-instance">
                                    <IconLinkedin1 className="icon-instance-node-2" />
                                    <div className="text-wrapper-12">LinkedIn</div>
                                </div>

                                <div className="footer-link-instance">
                                    <IconYoutube1 className="icon-instance-node-2" />
                                    <div className="text-wrapper-12">YouTube</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-div-5">
                    <div className="divider" />

                    <FooterImpressum className="rights-impressum-instance" />
                </div>
            </div>
        </>
    );
};
