import React from "react";
import "./OnboardingStatusBar.css";

export const OnboardingStatusBar = ({ State, Max = 5 }) => {
    return (
        <div className="status-onboarding-bar">
            <div className="overlap-group">
                <img
                    className="line"
                    alt="Line"
                    src="https://c.animaapp.com/D1wmOhGo/img/line-1.svg"
                />

                <div className="progress">
                    {[...Array(State)].map((e, i) => <div className="ellipse" />)}
                    {[...Array(Max - State)].map((e, i) => <div className="ellipse-2" />)}
                </div>
            </div>
        </div>
    );
};


