
import PropTypes from "prop-types";
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Footer } from "../../components/Footer.jsx";
import { NavbarLoggedIn } from "../../components/NavbarSignedIn.jsx";
import { Buttons } from "../../components/Buttons";

export const ChangePassword = ({ supabase }) => {
    const [msg, setMsg] = useState("");
    const inputRef1 = useRef();

    const SetPassword = async (event) => {
        const { data, error } = await supabase.auth.updateUser({
            password: inputRef1.current.value
        })
        if (error === null)
            setMsg("Password set!")
        else
            setMsg("Error Occurred!")
        console.log("Set: " + JSON.stringify(error))
    }
    return (
        <div>
            <NavbarLoggedIn supabase={supabase} />
            <div className="Container">
                <h1>Change Password</h1>
                <div className="input-form">
                    <input className="type-default type-default-alternate-false" ref={inputRef1} type="password" id="pwNew1" />
                    <Buttons onClick={SetPassword} text="Change" />
                </div>
                <p><br></br>{msg}</p>
            </div>
            <Footer />
        </div >
    );
};

ChangePassword.propTypes = {
    supabase: PropTypes.object,
};
