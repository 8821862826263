import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const SelectionButton = ({
    active = false,
    text = "Button",
}) => {
    return (
        <div className={`${active ? "selection-primary-active" : "selection-primary"}`}>
            <div className={`button`}>{text}</div>
        </div>
    );
};

SelectionButton.propTypes = {
    text: PropTypes.string,
};
