import { useEffect, useState } from "react";

export function useWindowWidth() {
    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return screenWidth;
}

