import React from "react";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { Buttons } from "../components/Buttons";
import { useWindowWidth } from "../breakpoints";
import { Footer } from "../components/Footer";
import { StylePrimarySmall } from "../components/StylePrimarySmall";
import { IconCube } from "../icons/IconCube";
import { IconUser2 } from "../icons/IconUser2";
import { useAuth } from "../Auth/Auth";

export const WebLandingpage = ({ }) => {
  const { user } = useAuth();
  const screenWidth = useWindowWidth();
  const loggedIn = user
  return (
    <div className="web-landingpage">
      <div className="web-landingpage-v">
        <div className="navbar">
          <div className="container">
            <Link to="/">
              <div className="logo-div">
                <img
                  className="logo-p-only"
                  alt="Logo p only"
                  src="https://c.animaapp.com/DyOG5xu3/img/logo-p-only-2@2x.png"
                />

                <div className="text-primary">Pamina - Career AI</div>
              </div>
            </Link>
            {loggedIn && (
              <Link to="/joblisting">
                <IconUser2 className="icon-user-2" />
              </Link>

            )}
            {!loggedIn && (
              <Link to="/joblisting">
                <StylePrimarySmall
                  className="style-primary-small-true-alternate-false-icon-position-no-icon"
                  divClassName="style-primary-small-instance"
                  text="Login"
                />
              </Link>
            )}
          </div>
        </div>

        {screenWidth < 1440 && (
          <>
            <div className="header">
              <div className="div-2">
                <div className="column-2">
                  <div className="div-3">
                    <p className="medium-length-hero">
                      <span className="span">Unlock </span>

                      <span className="text-wrapper-4">Your Dream Job</span>

                      <span className="span"> with AI Precision</span>
                    </p>

                    <p className="p">
                      Discover the perfect job tailored to your skills and
                      experience using our advanced AI technology. Let us
                      help you craft a standout cover letter that increases
                      your chances of landing an interview.
                    </p>
                  </div>
                  <Link to="/onboarding">
                    <Buttons
                      primary={true}
                      text="Find Me my next Job"
                    />
                  </Link>
                </div>

                <div className="content">
                  <div className="column-3">
                    <img
                      className="placeholder-image"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image@2x.png"
                    />

                    <img
                      className="img"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-1@2x.png"
                    />

                    <img
                      className="placeholder-image-2"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-2@2x.png"
                    />
                  </div>

                  <div className="column-4">
                    <img
                      className="placeholder-image-3"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-3@2x.png"
                    />

                    <img
                      className="placeholder-image-4"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-4@2x.png"
                    />

                    <img
                      className="placeholder-image-5"
                      alt=""
                      src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-5@2x.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="div-4">
              <p className="heading">
                <span className="text-wrapper-5">Discover Your </span>

                <span className="text-wrapper-6">Perfect Job Match</span>

                <span className="text-wrapper-5">
                  {" "}
                  with Our AI-Powered Service
                </span>
              </p>

              <div className="row">
                <div className="div-5">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-2">
                      <p className="heading-2">
                        Medium length section heading goes here
                      </p>

                      <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse varius enim in eros elementum
                        tristique.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="div-5">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-2">
                      <p className="heading-2">
                        Medium length section heading goes here
                      </p>

                      <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse varius enim in eros elementum
                        tristique.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="div-5">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-2">
                      <p className="heading-2">
                        Medium length section heading goes here
                      </p>

                      <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse varius enim in eros elementum
                        tristique.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="div-5">
                  <div className="section-title">
                    <IconCube className="icon-relume" color="#CF5AB7" />
                    <div className="content-2">
                      <p className="heading-2">
                        Medium length section heading goes here
                      </p>

                      <p className="text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit. Suspendisse varius enim in eros elementum
                        tristique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-wrapper">
              <div className="div-6">
                <div className="div-7">
                  <div className="div-7">
                    <div className="div-8">
                      <div className="tagline-wrapper">
                        <div className="tagline">Match</div>
                      </div>

                      <div className="div-3">
                        <p className="heading-3">
                          <span className="text-wrapper-7">
                            Exhaustive AI Search
                          </span>

                          <span className="text-wrapper-8">
                            {" "}
                            to Find Your Dream Job
                          </span>
                        </p>

                        <p className="p">
                          Our AI-driven job matching service connects you
                          with the best opportunities tailored to your
                          unique skills and experience. Say goodbye to
                          endless job searching and let us find the perfect
                          fit for you.
                        </p>
                      </div>
                    </div>
                    <Link to="/onboarding">
                      <Buttons
                        primary={true}
                        text="Show Me my Job offers"
                      />
                    </Link>
                  </div>
                </div>

                <img
                  className="placeholder-image-6"
                  alt=""
                  src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-6@2x.png"
                />
              </div>
            </div>

            <div className="container-wrapper">
              <div className="div-6">
                <div className="div-3">
                  <p className="heading-3">
                    <span className="text-wrapper-8">Crafting the </span>

                    <span className="text-wrapper-7">
                      Perfect Cover Letter Made Easy
                    </span>

                    <span className="text-wrapper-8">
                      {" "}
                      for Your Job Applications
                    </span>
                  </p>

                  <p className="p">
                    Our cover letter writing service simplifies your
                    application process, ensuring you stand out. With
                    expertly crafted letters tailored to your unique skills,
                    you’ll significantly increase your chances of landing
                    that interview.
                  </p>
                </div>

                <img
                  className="placeholder-image-6"
                  alt=""
                  src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-7@2x.png"
                />
              </div>
            </div>

            <div className="layout">
              <div className="div-6">
                <div className="div-7">
                  <div className="div-3">
                    <p className="text-wrapper-9">
                      Unlock Your Career Potential with Our AI-Driven Job
                      Matching Technology
                    </p>

                    <p className="p">
                      Our innovative platform has helped thousands find
                      their dream jobs. Join the community of satisfied
                      users who have transformed their careers with us.
                    </p>
                  </div>

                  <div className="list">
                    <div className="list-item">
                      <div className="text-wrapper-9">90%</div>

                      <p className="p">
                        User satisfaction rate based on recent surveys.
                      </p>
                    </div>

                    <div className="list-item">
                      <div className="text-wrapper-9">75%</div>

                      <p className="p">
                        Success rate for job placements through our service.
                      </p>
                    </div>
                  </div>
                </div>

                <img
                  className="placeholder-image-6"
                  alt=""
                  src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-8@2x.png"
                />
              </div>
            </div>

            <div className="container-wrapper">
              <div className="div-6">
                <div className="div-7">
                  <div className="div-3">
                    <p className="heading-3">
                      <span className="text-wrapper-8">Unlock </span>

                      <span className="text-wrapper-7">Your Dream Job</span>

                      <span className="text-wrapper-8"> Today</span>
                    </p>

                    <p className="p">
                      Join us to discover tailored job opportunities and
                      enhance your application with expert cover letters.
                    </p>
                  </div>
                  <Link to="/onboarding">
                    <Buttons
                      primary={true}
                      text="Get Me my Dream Job"
                    />
                  </Link>
                </div>

                <img
                  className="placeholder-image-7"
                  alt=""
                  src="https://c.animaapp.com/DyOG5xu3/img/placeholder-image-9@2x.png"
                />
              </div>
            </div>
          </>
        )}

        {screenWidth > 1440 && (
          <>
            <div className="web-home">

              <div className="header">
                <div className="container-2">
                  <div className="column-3">
                    <div className="content">
                      <p className="medium-length-hero">
                        <span className="span">Unlock </span>

                        <span className="text-wrapper-4">Your Dream Job</span>

                        <span className="span"> with AI Precision</span>
                      </p>

                      <p className="p">
                        Discover the perfect job tailored to your skills and
                        experience using our advanced AI technology. Let us help you
                        craft a standout cover letter that increases your chances of
                        landing an interview.
                      </p>
                    </div>
                    <Link to="/onboarding">
                      <Buttons
                        primary={true}
                        text="Find Me my next Job"
                      />
                    </Link>
                  </div>

                  <div className="content-2">
                    <div className="column-4">
                      <img
                        className="placeholder-image"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image@2x.png"
                      />

                      <img
                        className="placeholder-image"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-1@2x.png"
                      />

                      <img
                        className="img"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-2@2x.png"
                      />
                    </div>

                    <div className="column-5">
                      <img
                        className="img"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-3@2x.png"
                      />

                      <img
                        className="placeholder-image"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-4@2x.png"
                      />

                      <img
                        className="placeholder-image"
                        alt=""
                        src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-5@2x.png"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="div-3">
                <p className="heading">
                  <span className="text-wrapper-5">Discover Your </span>

                  <span className="text-wrapper-6">Perfect Job Match</span>

                  <span className="text-wrapper-5">
                    {" "}
                    with Our AI-Powered Service
                  </span>
                </p>

                <div className="row-wrapper">
                  <div className="row">
                    <div className="content-3">
                      <div className="section-title">
                        <IconCube className="icon-relume" color="#CF5AB7" />
                        <div className="content-4">
                          <p className="heading-2">
                            Saves hours of searching for the best job offers
                          </p>

                          <p className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse varius enim in eros elementum tristique.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="content-3">
                      <div className="section-title">
                        <IconCube className="icon-relume" color="#CF5AB7" />
                        <div className="content-4">
                          <p className="heading-2">
                            Don’t miss out on any good job offer
                          </p>

                          <p className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse varius enim in eros elementum tristique.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="content-3">
                      <div className="section-title">
                        <IconCube className="icon-relume" color="#CF5AB7" />
                        <div className="content-4">
                          <p className="heading-2">
                            Don’t waste time adapting your CV and Motivation Letter
                            for each offer
                          </p>

                          <p className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse varius enim in eros elementum tristique.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="content-3">
                      <div className="section-title">
                        <IconCube className="icon-relume" color="#CF5AB7" />
                        <div className="content-4">
                          <p className="heading-2">
                            Easily apply to hundreds of job offers
                          </p>

                          <p className="text">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Suspendisse varius enim in eros elementum tristique.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-wrapper">
                <div className="container-3">
                  <div className="div-4">
                    <div className="div-5">
                      <div className="div-6">
                        <div className="tagline-wrapper">
                          <div className="tagline">Match</div>
                        </div>

                        <div className="content">
                          <p className="heading-3">
                            <span className="text-wrapper-7">
                              Exhaustive AI Search
                            </span>

                            <span className="text-wrapper-8">
                              {" "}
                              to Find Your Dream Job
                            </span>
                          </p>

                          <p className="p">
                            Our AI-driven job matching service connects you with the
                            best opportunities tailored to your unique skills and
                            experience. Say goodbye to endless job searching and let
                            us find the perfect fit for you.
                          </p>
                        </div>
                      </div>
                    </div>
                    <Link to="/onboarding">
                      <Buttons
                        className="buttons-instance"
                        text="Show Me my Job offers"
                      />
                    </Link>
                  </div>

                  <img
                    className="placeholder-image-2"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-6.png"
                  />
                </div>
              </div>

              <div className="div-3">
                <div className="container-3">
                  <img
                    className="placeholder-image-2"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-7.png"
                  />

                  <div className="content-5">
                    <p className="heading-4">
                      <span className="text-wrapper-5">Crafting the </span>

                      <span className="text-wrapper-6">
                        Perfect Cover Letter Made Easy
                      </span>

                      <span className="text-wrapper-5">
                        {" "}
                        for Your Job Applications
                      </span>
                    </p>

                    <p className="p">
                      Our cover letter writing service simplifies your application
                      process, ensuring you stand out. With expertly crafted letters
                      tailored to your unique skills, you’ll significantly increase
                      your chances of landing that interview.
                    </p>
                  </div>
                </div>
              </div>

              <div className="layout">
                <div className="container-3">
                  <div className="div-4">
                    <div className="content">
                      <p className="heading-5">
                        Unlock Your Career Potential with Our AI-Driven Job Matching
                        Technology
                      </p>

                      <p className="p">
                        Our innovative platform has helped thousands find their
                        dream jobs. Join the community of satisfied users who have
                        transformed their careers with us.
                      </p>
                    </div>

                    <div className="div-6">
                      <div className="row-2">
                        <div className="list-item">
                          <div className="number">90%</div>

                          <p className="text-wrapper-9">
                            User satisfaction rate based on recent surveys.
                          </p>
                        </div>

                        <div className="list-item">
                          <div className="number">75%</div>

                          <p className="text-wrapper-9">
                            Success rate for job placements through our service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <img
                    className="placeholder-image-2"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-8.png"
                  />
                </div>
              </div>

              <div className="container-wrapper">
                <div className="container-3">
                  <div className="div-4">
                    <div className="content">
                      <p className="heading-3">
                        <span className="text-wrapper-8">Unlock Your </span>

                        <span className="text-wrapper-7">Dream Job</span>

                        <span className="text-wrapper-8"> Today</span>
                      </p>

                      <p className="p">
                        Join us to discover tailored job opportunities and enhance
                        your application with expert cover letters.
                      </p>
                    </div>
                    <Link to="/onboarding">
                      <Buttons
                        className="buttons-instance"
                        divClassName="instance-node-3"
                        text="Get Me my Dream Job"
                      />
                    </Link>
                    <div className="div-2" />
                  </div>

                  <img
                    className="placeholder-image-3"
                    alt=""
                    src="https://c.animaapp.com/K7QBe6oZ/img/placeholder-image-9.png"
                  />
                </div>
              </div>

            </div>
          </>
        )}
        <Footer />
      </div>
    </div>
  );
};
