
import PropTypes from "prop-types";
import React, { useRef } from 'react';
import { Footer } from "../../components/Footer.jsx";
import { NavbarLoggedIn } from "../../components/NavbarSignedIn.jsx";
import { Buttons } from "../../components/Buttons";

export const ResetPassword = ({ supabase }) => {
    const inputRef1 = useRef();

    const SetPassword = async (event) => {
        const { data, error } = await supabase.auth.updateUser({
            password: inputRef1.current.value
        })
        console.log("Set: " + JSON.stringify(data))
    }
    return (
        <div>
            <NavbarLoggedIn supabase={supabase} />
            <div className="Container">
                <h1>Reset Password</h1>
                <div className="input-form">
                    <input className="type-default type-default-alternate-false" ref={inputRef1} type="password" id="pwNew1" />
                    <Buttons onClick={SetPassword} text="Change" />
                </div>
            </div>
            <Footer />
        </div >
    );
};

ResetPassword.propTypes = {
    supabase: PropTypes.object,
};
