import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useAuth } from "../Auth/Auth";

export const Login = ({ supabase, location }) => {
    const { user } = useAuth();
    return (
        <>
            {user && window.location.pathname === "/login" && window.location.assign(location === undefined ? "/joblisting" : location)}
            <div className='login-container'>
                <Auth redirectTo='/joblisting' supabaseClient={supabase} appearance={{
                    style: {
                        button: { background: 'var(--primary)', color: 'white', border: "0px" },
                        anchor: {}
                    },
                    theme: ThemeSupa
                }} /></div>
        </>);
};
