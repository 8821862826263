import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const FooterImpressum = ({ className, divClassName = "" }) => {
    return (
        <div className={`footer-last-line ${className}`}>
            <p className={`${divClassName}`}>© 2024 Pamina. All rights reserved.</p>

            <div className="footer-links-impressum">
                <Link to="/impressum">
                    <div className={`${divClassName}`}>Impressum</div>
                </Link>
                <Link to="/dsgvo">
                    <div className={`${divClassName}`}>Privacy Policy</div>
                </Link>
            </div>
        </div>
    );
};
