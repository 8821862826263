import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const StatusDropdown = ({
    status,
    onChange
}) => {
    return (<>
        <select value={status} className="frame" onChange={onChange}>
            <option value="0">New</option>
            <option value="1">Interested</option>
            <option value="2">CV sent</option>
            <option value="3">Interview</option>
            <option value="4">Accepted</option>
            <option value="5">Denied</option>
        </select>
    </>
    );
};

StatusDropdown.propTypes = {
    status: PropTypes.number,
    onChange: PropTypes.func,
};
