
import PropTypes from "prop-types";
import React from 'react';
import { useEffect } from 'react';
import { useAuth } from "../../Auth/Auth";

export const PaymentSuccessful = ({ supabase }) => {
    const { user } = useAuth();
    useEffect(() => {
        const putData = async () => {
            await supabase.from("Goals").update({
                paymentSuccess: true
            }).eq('user', user.id);
            window.location.assign("/joblisting")
        }
        putData().catch(console.error);
    }, [supabase]);

    return (
        <div>
            loading ...
        </div >
    );
};

PaymentSuccessful.propTypes = {
    supabase: PropTypes.object,
};
