import React from "react";
import { Link } from "react-router-dom";

export const NavbarOnboarding = ({ className }) => {
    return (
        <div className={`navbar-onboarding ${className}`}>
            <div className="container">
                <Link to="/">
                    <img
                        className="logo-p-only"
                        alt="Logo p only"
                        src="https://c.animaapp.com/D1wmOhGo/img/logo-p-only-1-1@2x.png"
                    />

                </Link>
                <div className="text-wrapper">Pamina - Career AI</div>
            </div>
        </div>
    );
};
