/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Link } from "react-router-dom";
import { StylePrimarySmall } from "./StylePrimarySmall";
import { IconUser } from "../icons/IconUser";
import "./NavbarLoggedInStyle.css";

export const NavbarLoggedIn = ({
    icon = <IconUser className="icon-user" />,
    supabase,
}) => {
    return (
        <div className="navbar-loggedin">
            <div className="container">
                <Link to="/">
                    <div className="logo-div">
                        <img
                            className="logo-p-only"
                            alt="Logo p only"
                            src="https://c.animaapp.com/cRZENORy/img/logo-p-only-1-1@2x.png"
                        />

                        <div className="text-primary">Pamina - Career AI</div>
                    </div>
                </Link>
                <div className="column">
                    <div className="div">
                        <div className="link" />

                        <div className="link" />

                        <div className="nav-link-dropdown-wrapper">
                            <div className="nav-link-dropdown">
                                <div className="chevron-down" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column-2" />
                <div onClick={async () => { await supabase.auth.signOut(); window.location.assign('/'); }} className="hover:cursor-pointer" >
                    <StylePrimarySmall
                        className="style-primary-small-true-alternate-false-icon-position-no-icon"
                        divClassName="style-primary-small-instance"
                        text="Logout"
                    />
                </div>
                {/* <Buttons text="Logout" /> */}
                {/* {icon} */}
            </div>
        </div>
    );
};
